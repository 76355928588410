<template>
  <div>
    <van-tabs v-model="active" color="rgb(58, 131, 244)" title-active-color="rgb(58, 131, 244)" @click="changeTab()">
      <van-tab title="低保信息">
        <div class="border"></div>
        <van-cell hover-class="none">
          <div class="form-head">
            <div class="form-blue-view"></div>
            <div class="form-title-view">低保信息</div>
          </div>
        </van-cell>
        <van-field
          :readonly="true"
          v-model="dataForm.isLowest"
          name="低保状态"
          label="低保状态"
        />
        <van-field
          :readonly="true"
          v-model="dataForm.type"
          name="低保类型"
          label="低保类型"
        />
        <van-field
          :readonly="true"
          v-model="dataForm.safeguardSum"
          name="保障总人数"
          label="保障总人数"
        />
        <van-field
          :readonly="true"
          v-model="dataForm.socialSecurity"
          name="救助证编号"
          label="救助证编号"
        />
        <van-field
          :readonly="true"
          v-model="dataForm.householderName"
          name="户主姓名"
          label="户主姓名"
        />
        <van-field
          :readonly="true"
          v-model="dataForm.householderIdNumber"
          name="户主身份证"
          label="户主身份证"
        />
        <van-field
          :readonly="true"
          v-model="dataForm.rescueDate"
          name="救助日期"
          label="救助日期"
        />
        <van-field
          :readonly="true"
          v-model="dataForm.screenTime"
          name="排查时间"
          label="排查时间"
        />
        <van-field
          :readonly="true"
          v-model="dataForm.needRemind"
          name="年审提醒"
          label="年审提醒"
        />
      </van-tab>
      <van-tab title="基本信息">
        <res-info ref="resInfo"></res-info>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import resInfo from './resInfo.vue'
export default {
  components: {
    resInfo
  },
  mounted () {
    this.options = this.$route.query
    this.id = this.options.id || ''
    if (this.id) {
      this.getInfo()
    }
  },
  data () {
    return {
      dataForm: {
        isLowest: '',
        type: '',
        safeguardSum: '',
        socialSecurity: '',
        householderName: '',
        householderIdNumber: '',
        needRemind: '',
        rescueDate: '',
        screenTime: ''
      },
      options: null,
      init: true,
      active: 0,
      id: ''
    }
  },
  methods: {
    changeTab () {
      if (this.active == 1 && this.init) {
        this.init = false
        this.$nextTick(() => {
          this.$refs.resInfo.init(this.options, false)
          this.$refs.resInfo.control.disabled = true
        })

      }
    },
    getInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/social/lowest/info'),
        method: 'get',
        params: this.$http.adornParams({
          id: this.id
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataForm.isLowest = data.appSocialLowest.isLowest == 1 ? '低保户' : '非低保户'
          this.dataForm.type = data.appSocialLowest.type == 1 ? '收入型低保' : data.appSocialLowest.type == 2 ? '支出型低保' : '残疾人单列户施保'
          this.dataForm.safeguardSum = data.appSocialLowest.safeguardSum
          this.dataForm.socialSecurity = data.appSocialLowest.socialSecurity
          this.dataForm.householderName = data.appSocialLowest.householderName
          this.dataForm.householderIdNumber = data.appSocialLowest.householderIdNumber
          this.dataForm.needRemind = data.appSocialLowest.isRemind == 1 ? '是' : '否'
          this.dataForm.rescueDate = data.appSocialLowest.rescueDate
          this.dataForm.screenTime = data.appSocialLowest.screenTime
        } else {
          this.$message.error(data.msg)
        }
      })
    }
  }
}
</script>
<style scoped>
.border {
  width: 100%;
  height: 20px;
  background-color: #f5f5f5;
}
::v-deep .van-tab:first-child:after {
  content: '';
  position: absolute; 
  width: 3px;
  height: 50px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f5f5f5; 
} 
</style>